

.scraper-params-form-button {
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: inherit;
  font-size: 16px;
  font-weight: 600;
  border: 2px solid #707070;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 270px;
  padding: 5px 15px;
  width: auto;
  margin-top: 20px;
}