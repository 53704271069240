body { font-family: 'Avenir Next Font', sans-serif !important; }
.RowData .MuiGrid-root.MuiGrid-item.MessageEventTitle { padding-top: 5px !important; }
.RowData .MuiGrid-root.MuiGrid-item.MessageEventTitleBottom { padding-bottom: 6px !important; }
.button-postion-bottom { position: absolute; bottom: 0px; }
.fileContainer { padding-top: 0; justify-content: center; width: 50%; border: solid 1px #ccc; padding: 10px; margin-top: 0; margin-bottom: 0; margin-bottom: 10px; }
.text-center { text-align: center; }
.fileContainer .uploadPicturesWrapper { position: absolute; right: 0; display: inline-block; width: 200px; top: 0; height: 100%; }
.uploadPicturesWrapper > div { width: auto !important; flex-direction: initial !important; display: inherit !important; }
.fileContainer .uploadPictureContainer { width: auto; height: 85px; }
.fileContainer .uploadPictureContainer img.uploadPicture { width: 100%; object-fit: contain; height: 100%; }
.fileContainer .uploadPicturesWrapper { position: inherit; }
.fileContainer .deleteImage { background: #f44336; font-size: 16px; }
html { height: 100%; }
body { min-height: 100%; display: grid; grid-template-rows: 1fr auto; }
.Inner_scroll.SettingsCust { padding-bottom: 195px; }
.BorderPrimary { border: solid 1px #707070; }
.w-100 { width: 100%; }
.form-control .MuiOutlinedInput-notchedOutline { border-color: #707070; border-width: 2px; border-radius: 0px; }
.NotificationsHead .form-control .MuiOutlinedInput-notchedOutline { border-width: 1px; }

.d-block { display: block; }

.footer { height: 120px; position: fixed; bottom: 0; left: 0; right: 0; background: #fff; }
.ListItem .MuiListItemText-multiline { margin: 0; }
.content-container { position: relative; }
.MuiAppBar-positionFixed { box-shadow: none !important;  height: 177px; }
.app-header.mega-header .MuiAppBar-positionFixed { height: 170px; }
.LogIn { display: flex; height: 100%; justify-content: center; align-items: center; position: absolute; top: 0; bottom: 0; width: 100%;  /* background: #000;*/ }
.footer .MuiBox-root.MuiBox-root-15.inner-footer, .footer .MuiBox-root { margin-top: 0; padding-top: 1rem; padding-bottom: 1rem; background: #fff; border-color: #707070; border-width: 3px; }
.ux-custom-form .MuiFormControl-marginNormal { margin-top: 0px; margin-bottom: 1.5rem; }
.ux-custom-form .MuiFormHelperText-root.Mui-error { position: absolute; bottom: -19px; margin-left: 0px; }
code { font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }
a { text-decoration: none; }

/* Toggle Button */
.MuiToggleButtonGroup-groupedHorizontal:not(:first-child) { margin-left: 0 !important; }
button.MuiButtonBase-root.MuiToggleButton-root.MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal.Mui-selected, button.MuiButtonBase-root.MuiToggleButton-root.MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal:hover { color: #3b8450; background: #fff; }

/* Mdoules Headers Overrides */
span.MuiTypography-root.MuiCardHeader-title.MuiTypography-h5.MuiTypography-displayBlock { display: flex; margin: auto; width: calc(100% - 10rem); text-align: center; background: #fff; padding: 7px 0.725rem 0.625rem; font-weight: bold; justify-content: center; align-items: center; font-size: 1.5rem; }
span.MuiTypography-root.MuiCardHeader-title.MuiTypography-h5.MuiTypography-displayBlock a { color: #707070; cursor: pointer; }
span.MuiTypography-root.MuiCardHeader-title.MuiTypography-h5.MuiTypography-displayBlock span.current-header > a { color: #008244; cursor: pointer; }
.current-header-arrow.arrow-inactive { color: #707070; }
.current-header-arrow.arrow-active { color: #008244; }
.MuiCardHeader-content { width: 100%; background-color: #008244; border: 1px #707070 solid; }
.MuiCardHeader-root { padding: 0px !important; background: #fff !important; flex-wrap: wrap !important; }
.MuiCardHeader-action { margin: 0 0 0 auto !important; width: 100%; display: flex; }

/* Dashboard Buttons */
button.dashboard-btn.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-outlinedSizeLarge.MuiButton-sizeLarge.MuiButton-fullWidth { padding: 0; background: #008244; }
.AdminDashboard-row .MuiButton-root { border-radius: 22px; border: 1px solid #707070; }

/*   
.Inner_scroll-con::-webkit-scrollbar{width:16px;}
.Inner_scroll-con::-webkit-scrollbar-track{border:solid 1px #707070;border-radius:4px;}
.Inner_scroll-con::-webkit-scrollbar-thumb{background-color:#aaaaaa;border:solid 1px #707070;border-radius:4px;} */
::-webkit-scrollbar-track {border-radius: 2px;-webkit-box-shadow: inset 0 0px 0px 2px rgb(112 112 112 / 50%);background: #fff; }
::-webkit-scrollbar-thumb { background-color: #aaaaaa; border: solid 1px #707070; border-radius: 2px;  }
::-webkit-scrollbar { width: 10px; }

:root { scrollbar-color: #aaaaaa #fff !important; scrollbar-width: thick !important; -webkit-overflow-scrolling: auto !important; }
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active { -webkit-transition: "color 9999s ease-out, background-color ffffff ease-out"; -webkit-transition-delay: 9999s; }

/* .tableScrollbar::-webkit-scrollbar{width:1em;}
.tableScrollbar::-webkit-scrollbar-track{border:solid 1px #707070;border-radius:0px;border-top:none;border-top:none;}
.tableScrollbar::-webkit-scrollbar-thumb{border-top:none;background-color:#aaaaaa;border:solid 1px #707070;border-radius:0px;} */
.dashboard-btn span.MuiButton-label { background: #fff; width: calc(100% - 12rem); padding: 5px 40px; color: #000000; line-height: 25px; height: 47px; font-size: 20px; text-transform: capitalize; font-weight: 400; word-break: break-all; }
.MuiTableCell-head { background-color: #008244 !important; }
.fToorTime { display: inline-block; text-align: left; }
.dashboard-grid { padding-top: 20px; }
.MessageOuter > .MuiCardHeader-content { display: none; }
.dashboard-grid.cenveyor-belts-menu:first-child { padding-top: 0; }
.Speacing span.MuiButton-label { width: calc(100% - 7rem); }
.icon-size img { height: 50px; }
.mega-header .big-logo { height: 120px; margin-bottom: 0px; margin-top: 7px; }
.h-100, .ux-custom-form, .ux-custom-form>.MuiCardContent-root { height: 100%; }
.ux-custom-form { height: 100%; padding-bottom: 20px; }
.app-header { margin-bottom: 0 !important; position: fixed; z-index: 999; }
.big-logo { height: 90px; margin-bottom: 14px; margin-top: 14px; }
body { color: #000 !important; background: #fff !important; }
.footer { font-size: 14px; color: #000; }
.mega-header .BtnLanguge { box-shadow: none !important; }
.BtnLanguge .MuiButtonGroup-grouped { font-weight: 400; color: #000; background: none !important; padding: 0px 5px; box-shadow: none !important; font-size: 25px; line-height: 24px; border-color: #000; }
.BtnLanguge .MuiButtonGroup-groupedContainedHorizontal:not(:last-child) { border-right: 1px solid #000; }
.HomeBtn { margin-left: 65px; }
.BackBtn { margin-left: -15px; }
.MuiButton-containedPrimary span { font-weight: bold; }
button.dashboard-btn:hover { border: 1px solid #707070; }
.MessageOuter .MuiButton-outlined { border-radius: 0px; border: 2px solid #707070; text-transform: capitalize; font-weight: bold; font-size: 20px; height: 50px; color: #000; }
.green-bg { background-color: #008244; }
.white-bg { background-color: #ffff; }
.ContentHeader { border: solid 1px #707070; margin-bottom: 0; overflow: hidden; border-bottom: none; }
.mega-header .ContentHeader { display: none; }
.TableCustom .MuiTableCell-root { border: solid 2px #707070; }
table { border-collapse: separate; border-spacing: 0; }
.RowData { border: solid 2px #707070; border-radius: 30px; }
.RowData { margin-bottom: 8px !important; }
.RowData .Mid-Con { font-size: 20px; color: #000; padding-left: 20px !important; padding-left: 20px !important; }
.RowData .Last-Con, .ListItem .MuiListItemText-root .MuiTypography-root { font-size: 25px; color: #000; text-align: center; }
.RowData .MuiGrid-root.MuiGrid-item { padding: 10px; }
.Inner_content { padding-top: 35px; }

/* .react-tabs__tab-panel{ padding-top: 50px !important;} */
.RowData .Last-Con { display: flex; align-items: center; justify-content: center; flex-wrap: wrap; border-left: solid 1px #707070; color: #000; }
.Inner_scroll-con { height: 100%; overflow: auto; padding-top: 10px; }
.Inner_scroll-con { margin-right: -9px; padding-right: 1rem !important; }
.CardBorderStyle { padding-left: 10px; padding-right: 10px; }
.AddBtn { position: absolute; }
.Inner_scroll { height: 100%; padding-bottom: 53px; }
.Inner_scroll.Message { padding-bottom: 160px; }
.pl-2 { padding-left: 2rem; }
.pr-2 { padding-right: 2rem; }
.ml-0-5 { margin-left: 0.5rem; }
.ml-2 { margin-left: 2rem; }
.ml-3 { margin-left: 3rem; }
.mr-2 { margin-right: 2rem; }
.pl-1 { padding-left: 1rem; }
.pl-0-5 { padding-left: 0.50rem !important; }
.pt-1 { padding-top: 0.5rem !important; }
.pb-1 { padding-bottom: 0.5rem !important; }
.mt-1 { margin-top: 1rem !important }
.mt-0 { margin-top: 0rem !important }
.pl-0 { padding-left: 0 !important; }
.pr-0 { padding-right: 0 !important; }
.pr-1 { padding-right: 1rem; }
.pr-1-5 { padding-right: 1rem; }
.ml-1 { margin-left: 1rem; }
.mr-1 { margin-right: 1rem; }
.mb-0 { margin-bottom: 0 !important; }
.pb-0 { padding-bottom: 0 !important; }
.pt-0 { padding-top: 0 !important; }
.mr-0 { margin-right: 0px !important; }
.ml-0 { margin-left: 0px !important; }
.Inner_scrollTwo { height: 100%; padding-bottom: 80px; }
.pb-6 { padding-bottom: 6rem; }
.imgfluid { max-width: 100%; height: auto; }
.CardBorderStyle { border: solid 1px #707070; box-shadow: none !important; border-radius: 0 !important; }
.left-Con { text-align: center; border-right: solid 1px #707070; color: #000; }
.scroll-overflow { max-height: 566px !important; overflow: auto; }
#root > .container > .content-container { height: 100%; padding-top: 165px; padding-bottom: 145px; }
.Pr-2 { padding-right: 2rem; }
.RowData-btn img { height: 35px; width: 35px; object-fit: contain; }
.AddBtn button { padding-top: 0; }
li.MuiListItem-root { padding-left: 0; padding-top: 0; padding-bottom: 0; }
.RowData-btn { padding-left: 7px; }
.CardBorderStyle>.MuiCardContent-root, .MainContInnerStyle { height: 100%; align-content: flex-start !important; }
.Inner_scrollTwoTable { height: 100%; padding-bottom: 110px; }
.position-relative { position: relative; }
.position-absolute { position: absolute; }
.MainInnerContent { width: 100%; bottom: 0; top: 0; }
.input-group { position: relative; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-align: stretch; align-items: stretch; border: solid 1px #707070; background: #008244; margin-top: 15px; margin-left: 15px; min-width: 140px; }

.imgthumb img { height: 151px; width: 336px; object-fit: contain; }
.align-items-center { align-items: center; }
.dflex { display: flex !important; }
.MuiListItemText-root { margin: 0 !important; }
.ButtonPanel .MuiButton-outlined { border: 2px solid #707070; border-radius: 15px; padding-left: 20px; padding-right: 20px; text-transform: inherit; }
.ButtonPanel { 
  /* margin-bottom: 8px; */
  margin-bottom: 0;
   margin-left: 10px; margin-right: 10px;
 }
.ButtonPanel .MuiButton-outlined { font-size: 16px;  padding: 3px 15px; }
.ButtonPanel .MuiButton-outlined img { height: 30px; width: 30px; object-fit: contain; margin-right: 5px; }
.ContentHeader.CardInnerHeader { border-radius: 8px; margin-bottom: 10px; border-bottom: solid 1px #707070; }
.CardInnerHeader-title { font-size: 20px !important; padding: 0px !important; }
.TokenUpRow.RowData { border-radius: 19px; }
.TokenUpRow.RowData .MuiGrid-root, .RowDataHead, .RowData-list-service .MuiGrid-root { font-size: 20px; }
.TokenUpRow.RowData .MuiGrid-root { border-left: solid 1px #707070 }
.TokenUpRow.RowData .MuiGrid-root:first-child { border-left: none; border-right: none; }
.TextLeft { text-align: left; }
.TextCenter { text-align: center; }
.TokenUpRow.RowData .MuiGrid-root.MuiGrid-item { padding: 12px 20px; }
.RowHeadwrap .MuiGrid-root { padding: 0px 20px; }
.makeStyles-root-4 { min-height: auto; }
.Inner_content, .Inner_content>.MuiGrid-container, .CardBorderStyle { height: 100%; position: relative; }

/* Container without search */
.Inner_content >.MuiGrid-container { height: 100%; position: relative; padding-top: 20px; }
/* Reduce height of content when search toolbar is shown */
/* height is 100% minus the height of the search toolbar */
.Inner_content.with-search-toolbar>.MuiGrid-container { height: calc(100% - 45px); position: relative; padding-top: 0px; }


#root > .container { height: 100%; }
.ButtonPanelWrap { position: absolute; bottom: 2px; width: 100%; background: #fff; left: 0; right: 0; padding: 8px 15px !important; }
.ButtonPanel .MuiBox-root { margin-top: 0; margin-bottom: 0;  /* padding-top: 18px; */ }
.ButtonPanelWrap-2 img { height: auto !important; width: auto !important; }
.Pagination .MuiPaginationItem-page { font-size: 22px; border-radius: 0px; }
.Pagination .MuiPaginationItem-icon { font-size: 40px; color: #000000; font-weight: 100; }
.Pagination .MuiPaginationItem-page.Mui-selected { background-color: #AAAAAA !important; color: #fff; }
.justify-content-center { justify-content: center; }
.justify-content-space-between { justify-content: space-between !important; }
.cancel-icon img { height: 20px; }
.makeStyles-areaTable-10 thead tr th { z-index: 99; }
table .MuiTableCell-head, .MuiTableCell-root { background: #ffff !important; color: #000000 !important; font-size: 14px !important; font-weight: bold !important; z-index: 999; }
.tableScrollbar tbody td:first-child, span.Text-righthead, span.Text-Bootmhead { font-size: 16px !important; }
table tbody .MuiTableCell-root { padding-top: 10px; padding-bottom: 10px; }
.CustomControl .MuiFormControl-root { width: 100%; }
.RowData .MuiRadio-root { padding: 0; }
.MuiMenu-list { padding: 0px 15px !important; border: solid 2px #707070; border-radius: 0; }
.MuiPopover-paper { border-radius: 0 !important; }
.CustomControl  .MuiInput-underline:before { border: none; }
.CustomControl { position: absolute; right: 0; left: 0; }
.CustomControl { position: absolute !important; right: 0; left: 0; height: 100%; overflow: hidden; }
.CustomControl .MuiInput-root { height: 100%; overflow: hidden; }
.CustomControl .MuiSelect-select.MuiSelect-select { padding-right: 35px; border: none !important; height: 100%; display: flex; align-items: center; padding-left: 10px; justify-content: center; }
.CustomControl .MuiSelect-icon { top: 0; color: rgba(0, 0, 0, 0.54); right: 0; position: absolute; pointer-events: none; background: #d0d0d0; height: 100%; border-left: solid 1px #707070;    /* font-size: 34px; */ width: 20px; border-right: solid 1px #707070; }
.MuiInput-underline:after { display: none; }
.TokenUpRow.RowData { align-items: stretch; }
.TokenUpRow.RowData .MuiGrid-root.MuiGrid-item { align-items: center; display: flex; }
.CustomControl .MuiInput-underline:hover:not(.Mui-disabled):before { border: none; }
.ButtonPanelWrap button:last-child { margin-right: 0 !important; }
.RowData.RowData-list-service .MuiGrid-root { padding-top: 0px !important; padding-bottom: 0px !important; }
.RowData.RowData-list-service { border-radius: 19px; line-height: 25px; }
.HrBorder { border-top: solid 2px #707070; }
.MainContInnerStyleTwo { height: calc(100vh - 573px); }

.MainContInnerStyleThree { height: calc(100vh - 530px); }
.border-0 { border: none; box-shadow: none !important; }
.align-content-start { align-content: flex-start; }
button.buttonDamage { border-radius: 0px !important; font-size: 18px; text-transform: capitalize; color: #C02F2F !important; }
.buttonDamage img { height: 40px; }
.RowData .Last-Con, .RowData .Mid-Con { word-break: break-all; }
.react-tabs__tab-list li { font-size: 18px; font-weight: 700; flex-grow: 1; flex-basis: 0; text-align: center; display: inline-flex; justify-content: center; align-items: center; border: 2px solid #707070; padding: 5px 12px; border-radius: 0px; position: relative; z-index: 999; }
.react-tabs__tab-list li { border-left: none; }
.react-tabs__tab-list li:first-child { border-left: 2px solid #707070; }
.react-tabs__tab-list { display: -ms-flexbox !important; display: flex !important; flex-direction: row; border-bottom: none; margin-bottom: 0; margin-top: -1px; }
.react-tabs__tab-list li { flex-grow: 1; flex-basis: 0; }
.react-tabs__tab--selected { background: #E8F4E6; border-left: none; }
.react-tabs__tab:focus:after { display: none; }
.react-tabs__tab:focus { box-shadow: none; border-left: none; }
.react-tabs__tab-panel--selected { display: block; border: 2px solid #707070; border-top: none; }

/* .form-control .MuiInputLabel-formControl{top:-3px;left:0;position:absolute;transform:translate(0,24px) scale(1);font-size:14px;} */

/* .form-control .MuiOutlinedInput-inputMarginDense{padding-top:7.5px;padding-bottom:7.5px;font-size:14px;} */
.TabsView { position: absolute; bottom: 0px; top: 100px; right: 30px; left: 30px; }
.react-tabs__tab-panel { height: 100%; padding: 10px; position: absolute; z-index: 9; padding-top: 45px; bottom: 0; top: 0px; right: 0; width: 100%; padding-right: 15px; padding-left: 15px; }
.react-tabs__tab-panel { padding-right: 30px; padding-left: 30px; }
.TabsView { top: 75px; }

/******* Checkbock*******/
.container-Checkbox { display: block; position: relative; padding-left: 35px; cursor: pointer; font-size: 16px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }
.container-Checkbox input { position: absolute; opacity: 0; cursor: pointer; height: 0; width: 0; }
.container-Checkbox:hover input ~ .checkmark { background-color: #ccc; }
.container-Checkbox input:checked ~ .checkmark { background-color: #fafafa; }
.checkmark:after { content: "X"; position: absolute; display: none; }
.container-Checkbox input:checked ~ .checkmark:after { display: block; }
.checkmark { position: absolute; top: 0; left: 0; height: 19px; width: 19px; background-color: #fafafa; border: solid 1px #707070; }
.container-Checkbox .checkmark:after { left: 3px; top: -2px; width: 5px; height: 5px; border: solid white; border-width: 0; transform: rotate(359deg); font-size: 15px; }

/**********************************/
.ButtonPanel.ButtonPanelSetting { display: block !important; }
.ButtonPanel.ButtonPanelSetting .MuiBox-root { margin: auto; }
.ButtonPanel.ButtonPanelSetting  .MuiButton-outlined{ margin-bottom: 10px;}
.ButtonPanelSetting.ButtonPanel .MuiButton-outlined { max-width: 270px; width: 100%; padding: 5px 15px;}
.ButtonPanelSettingWrap { border-top: solid 1px #707070; width: 100%; border-radius: 0; }
.ConMessageBeltScraper { padding-bottom: 45px; height: 100%; }
.Custometable th { font-weight: 700; }
table { border-collapse: collapse; }
.Custometable th, .Custometable td { border: 1px solid #707070; border-collapse: collapse; width: 16.66%; text-align: center; }
.BasicTable th, .BasicTable td { border: 1px solid #707070; border-collapse: collapse; text-align: center; }
.MenuScroll { height: 100%; overflow-y: auto; overflow-x: hidden; padding: 0px 15px; }
.MuiButton-label { font-weight: 400; }
.SettingsCustOuter input { padding: 3px 10px; max-width: 300px; width: 100%; border:solid 1px #707070 ;margin: 1px 0; }
.SettingsCustOuter .DatePicker.date input.MuiInputBase-input{ border: none;}
.DatePicker .MuiInput-root input { width: 100%; color: #000 !important; font-size: 13px; }
.DatePicker .MuiIconButton-root { padding: 0px 3px; }
.DatePicker .MuiInputLabel-shrink, .DatePicker .MuiInput-underline:before { display: none; }
.DatePicker label + .MuiInput-formControl { margin-top: 0; }
.DatePicker .MuiFormControl-root { border: solid 1px #707070; border-radius: 2px; width: 100%; max-width: 300px;}
.DatePicker label .MuiInputLabel-formControl { top: -18px; left: 10px; color: #000; }
#demo-simple-select .MuiMenu-list { }
.cancel-icon-col { max-width: 60px !important; width: 60px !important; }
.user-col { max-width: 160px !important; }
.TokenUpRow .checkmark { top: -14px; left: 15px; }
.MuiGrid-grid-xs-3.MaxWidth336 { max-width: 23.5%; }
.margin-auto { margin: auto !important; }
.MaxWidth336 { max-width: 23.5%; max-width: 23.5%; }
.RowData.TokenUpRow.User .MuiGrid-root.MuiGrid-item { display: block; }
.tableScrollbar { top: 10px; }
.tableScrollbar tbody td .MuiIconButton-root { padding: 2px !important; }
.tableScrollbar tbody td { border: 1px solid #707070 !important; border-collapse: collapse; border-top: none !important; border-left: none !important; }
.tableScrollbar tbody td, .tableScrollbar thead th { text-align: center; }
.tableScrollbar thead th { border: 1px solid #707070 !important; border-left: none !important; white-space: nowrap; z-index: 9; }
.tableScrollbar thead th:last-child, .tableScrollbar tbody td:last-child {border-right: none !important;}
.tableScrollbar{border-left: solid 1px #707070;}
.tableScrollbar table { border: solid 1px #707070; border-top: none; margin-left: -1px; }
tr.MuiTableRow-root:last-child td { border-bottom: none !important; }
.tableScrollbar th { height: 120px; white-space: nowrap; }
.ListCustomerRep .ListCustomerRepHeader .TokenUpRow{ margin-bottom: 0px !important;}
.tableScrollbar .makeStyles-areaTable-13 thead {
  left: 0;
  z-index: 9999;
  position: sticky;
}

/* .tableScrollbar tr td:last-child, .tableScrollbar thead th:last-child{ width: 60px !important; border-right: none !important;} */
.tableScrollbar tr td:last-child button { padding: 0; }
.Rotate-shape { display: block; background: #707070; height: 1px; left: -14px; right: 0; top: 59px; width: 286px; -ms-transform: rotate(24.3deg); -moz-transform: rotate(24.3deg); -webkit-transform: rotate(24.3deg); -o-transform: rotate(24.3deg); position: absolute; }
/* .MuiTableCell-stickyHeader{ position: relative !important;} */
span.Text-righthead { position: absolute; right: 30px; top: 20px; }
.text-right { text-align: right; }
span.Text-Bootmhead { position: absolute; left: 30px; bottom: 20px; }
.BtnLogout { margin-right: 20px !important; }
.MuiCardActions-spacing >:not(:first-child) { padding-left: 15px; padding-right: 15px; }
.Message-div { position: absolute; bottom: 0; top: 55px; padding-top: 0; right: 50px; left: 50px; z-index: 9; }
.mt-2 { margin-top: 2rem; }
.PrimaryColor { color: #000; }
.RowHeight70 { height: auto; }
.BeltCenveyorStyle, .InfoTabContent, .label-text, .NotificationsHead .MuiInputBase-input, .NotificationsHead .MuiFormLabel-root, .CustomControl .MuiSelect-select.MuiSelect-select { font-size: 16px !important; }
.d-flex { display: flex; }
.flex-wrap { flex-wrap: wrap; }
.NotificationsHead .MuiFormHelperText-root.Mui-error { color: #f44336; position: absolute; bottom: -17px; }
.NotificationsHead .MuiOutlinedInput-inputMarginDense { padding-top: 7.5px; padding-bottom: 7.5px; }
.border-left-0 { border-left: none !important; }
.border-right-0 { border-right: none !important; }
.cancel-icon.cancel-iconStyle { position: relative; right: 10px; }
.MessageOuter .MuiButton-outlined span.MuiButton-label { font-weight: 700; }
.recharts-wrapper { margin-left: -20px; }
.Message-div { top: 70px; }
.WifiIcon { margin-right: 15px; }
.MuiPaper-root { color: #000000 !important }
.flex-fill { flex: 1 1 auto !important; }
/* .TableFixed.tableScrollbar table, .TableFixed { table-layout: fixed; } */
.TableFixed.tableScrollbar table thead th:first-child{ white-space: nowrap; overflow: hidden; padding: 0PX 5px !important;     min-width: 260px!important;width: 260px; 
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 99999;
 
}

.TableFixed.tableScrollbar table tbody td:first-child {
  z-index:2 !important ;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}
.TextSpartaerDiv { width: 260px; display: flex; height: 100%; float: left; position: relative; display: block; }
.TextSpartaerDiv .displayed-text{word-break: break-all;}
.TextOverflowTh .displayed-text{ overflow: hidden; text-overflow: ellipsis; display: block;}
/* .TableFixed.tableScrollbar table thead th, .TableFixed.tableScrollbar table tbody td { width: 240px } */

/* .UserAreaTable.tableScrollbar td:last-child, .UserAreaTable.tableScrollbar th:last-child, .UserAreaTable.tableScrollbar td, 
.UserAreaTable.tableScrollbar th{width: 170px!important;white-space: pre-line;word-break: break-all;} */
.AccordionPanel { background:whitesmoke !important; box-shadow: none !important; border: solid 1px #707070; margin-bottom: 5px !important; border-radius: 0 !important; margin-top: 0px !important; }
.AccordionPanel .MuiAccordionSummary-root p { font-size: 15px; font-weight: 700; }
.AccordionPanel .MuiAccordionSummary-root { padding-top: 0; padding-bottom: 0; min-height: 44px !important; }
.AccordionPanel .MuiAccordionSummary-content { margin: 0 !important; }
.AccordionPanel svg[data-testid="ExpandMoreIcon"]{ display: none;}
.AccordionPanel .ArrowIcon { position: relative; top: 3px; }
.AccordionPanel .MuiAccordionDetails-root { background-color: #fff; border-top: solid 1px #707070; padding: 8px 13px 8px 13px; }
.greenText { color: #148B38; }
.TableAccordion td, .fs-13 { font-size: 12px; }
.visbilty-hidden { visibility: hidden; }
.ConBeltDashboard>div, .ConBeltDashboard { padding-left: 0; padding-right: 0; padding-top: 0; }
/* .TimeAndDate { font-size: 16px; padding: 5px; } */
.MuiAppBar-colorDefault { background-color: #707070 !important; }
/* .TimeAndDate { margin-bottom: 9px; } */
.MuiAppBar-colorDefault .PrivateSwitchBase-root-35, .MuiAppBar-colorDefault .MuiIconButton-root { padding: 0px !important; }
/* .TimeAndDate img { width: 35px; } */
.select select option { color: #000; padding: 0 10px; }
select::-ms-expand { display: none; }

.DamageButton .MuiButton-outlined { border: 1px solid rgba(0, 0, 0, 0.23); padding: 1px 15px; border: solid 2px #707070; border-radius: 8px; font-size: 17px; text-transform: inherit; font-weight: bold; }
.DamageButton .MuiButton-outlined .MuiButton-label { font-weight: bold; color: #000; }
.DamageButton .RedText .MuiButton-label, .red-text { color: #EF0202; }
.MuiMenu-list li { border-bottom: solid 1px #707070; padding-bottom: 4px; padding-top: 4px; }
.MuiMenu-list li:last-child { border-bottom: none; }
.ConveyorBeltDashboardStatus { font-size: 15px; }
.segmentTable input { height: auto; padding: 15px !important; }
.segmentTable .MuiOutlinedInput-root { border-radius: 0 !important; }
.segmentTable .MuiOutlinedInput-notchedOutline { border: 1px solid #707070; outline: none; box-shadow: none; }
.button-postion-bottom.LeftSide { left: 10px; }
.button-postion-bottom.RightSide { right: 10px }
.SettingButtonDiv{    display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  align-content: stretch;}
  .ButtonPanelSettingWrap{  margin-left: 15px;}
  .DatePicker .MuiIconButton-label .MuiSvgIcon-root {
    height: 18px;
    width: 18px;
    position: relative;
}
.Versiontext{    color: #000;
  position: absolute;
  top: 30%;
  left: 15px; font-size: 10px;}
  .DamageCounter {
    font-family: 'Avenir Next Font', sans-serif;
}
.app-multiselect>div{    min-height: 45px;}
.WordWrap{ word-wrap: break-word;}
.WordWrap .displayed-text{    word-break: break-all;}
.MuiPaginationItem-page.Mui-selected:hover, .MuiPaginationItem-page.Mui-selected.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}
.Inner_scroll-con.PR-0{ padding-right: 10px !important;}
.BeltDamageBottm{ position: absolute; bottom: 15px; right: 0; left: 0px; padding-left: 30px; padding-right: 30px;}
   .TableFixed.UserAreaTable tbody.MuiTableBody-root tr td:first-child {
  border-right: 1px solid #707070 !important;
}
.only-btn {
  align-items: flex-end !important;
}
.LogIn div > a {
  color: #008244;
}
.tableScrollbar table .MuiTableHead-root{ z-index: 999 !important;}
/***************************************
 Media Query
 **************************************/
@media (max-width:1900px) {
  .ButtonPanel.ButtonPanelSetting .MuiButton-outlined.SubmitSettingBtn{  min-width: none; width: auto;}
  .NotificationsHead .MuiOutlinedInput-inputMarginDense{     padding-top: 7.5px !important}
  .Inner_scroll-con .RowData { margin-bottom: 5px !important; }
  .BtnLogout .logout-small{ display: block !important;}
  .BtnLogout .logout-big{ display: none;}
 .WifiIcon { height: 40px !important;
    width: auto !important;
    /* border: solid 1px #000;
    border-radius: 50%;
    overflow: hidden;
    object-fit: contain; */
  }
  
  /* .DatePicker .MuiInput-root input { min-width: 240px; color: #000 !important; padding: 3px 10px; } */
  .Message-div { top: 62px; }
  .RowData .MuiIconButton-root { padding: 5px !important; }
  .MuiFormControl-root .MuiInputLabel-outlined { transform: translate(14px, 14px) scale(1); }
  .mega-header .big-logo { height: 75px; }
  .app-header.mega-header .MuiAppBar-positionFixed { height: 90px; }
  .footer { height: 80px; }
  .ftr-text { font-size: 9px; }
  .footer .MuiBox-root.MuiBox-root-15.inner-footer, .footer .MuiBox-root { padding-top: 6px; padding-bottom: 1rem; border-width: 2px; }
  #root > .container > .content-container { padding-top: 100px; padding-bottom: 95px; }
  .dashboard-btn span.MuiButton-label { line-height: 22px; height: 50px; font-size: 18px; }
  .AdminDashboard-row .MuiButton-root { border-radius: 15px; }
  .Speacing span.MuiButton-label { width: calc(100% - 4rem); }
  .MuiAppBar-positionFixed { height: 129px; }
  .big-logo { height: 70px; margin-top: 5px; margin-bottom: 5px; }
  .button-postion-bottom { bottom: -5px; }
  span.MuiTypography-root.MuiCardHeader-title.MuiTypography-h5.MuiTypography-displayBlock { padding: 0.3rem; font-size: 1.3rem; height: 40px; }

  /* .BackBtn{margin-left:54px;}
  .HomeBtn{margin-left:15px;} */
  .icon-size img { height: 40px; }
  .ContentHeader>.MuiContainer-root { margin: 0px auto; }
  .Pagination .MuiPaginationItem-page { font-size: 13px; height: 25px; }
  .Pagination .MuiPaginationItem-icon { font-size: 22px; }
  .RowData { border: solid 1px #707070; border-radius: 15px; margin-bottom: 10px !important; }
  .RowData .Mid-Con, .RowData .Last-Con, .ListItem .MuiListItemText-root .MuiTypography-root, .TokenUpRow.RowData .MuiGrid-root, .RowDataHead, .RowData-list-service .MuiGrid-root { font-size: 15px; }
  .RowData-btn img { width: 27px; object-fit: contain; }
  .MuiCardContent-root { padding: 10px !important; }
  .AddBtn { top: 20px; left: 30px; }
  .Pr-2 { padding-right: 1rem; }
  .RowData .MuiGrid-root.MuiGrid-item { padding: 5px; }
  .ButtonPanel .MuiButton-outlined img { width: 24px !important; margin-right: 3px; }
  .MuiOutlinedInput-input { padding: 10px 14px 12px !important; font-size: 16px !important; }
  
  .input-group { margin-top: 0px; margin-left: 5px; min-width: 150px; }
  /* .TimeAndDate { width: 148px; } */
  .ContentHeader.CardInnerHeader { border-radius: 5px; margin-bottom: 5px; }
  .TokenUpRow.RowData .MuiGrid-root.MuiGrid-item { padding: 5px 15px; }
  .CardInnerHeader-title { font-size: 18px !important; }
  .TokenUpRow.RowData, .RowData.RowData-list-service { border-radius: 10px; }
  .mr-1 { margin-right: 0.5rem; }
  .ml-1 { margin-left: 0.5rem; }
  .pr-1 { padding-right: 0.5rem; }
  .pl-1 { padding-left: 0.5rem; }
  .pl-2 { padding-left: 1rem; }
  .ButtonPanel .MuiButton-outlined { font-size: 16px; font-weight: 600; border: 2px solid #707070; border-radius: 10px; padding-left: 13px; padding-right: 13px; }

  .MainContInnerStyleTwo { height: calc(100vh - 400px); }
  
  .ButtonPanel { margin-bottom: 5px !important; margin-top: 0px !important; }
  .MuiTableCell-root { padding: 6px 12px; }
  .Inner_scrollTwo { padding-bottom: 38px; }
  .RowData .Mid-Con { padding-left: 10px !important; padding-left: 10px !important; }
  .MessageOuter .MuiButton-outlined { font-size: 18px; height: 45px; min-height: 45px; }
  .react-tabs__tab-list li { font-size: 13px; }
  .Inner_scroll.Message { padding-bottom: 130px; }
  .imgthumb img { height: 100px; width: 200px; }
  .MainContInnerStyleThree { height: calc(100vh - 380px); }
  .MessageOuter .MuiCardHeader-content { display: none; }
  .HrBorder { border-top: solid 1px #707070; }
  .TabsView { top: 55px; }
  .label-text-notifi-form { margin-top: 8px; }
  .ml-3 { margin-left: 1.5rem; }
  .ml-2 { margin-left: 1rem; }
  .DamageCounter {
    background-color: rgb(204, 62, 62);
    position: absolute;
    right: -20px;
    top: -11px;
    height: 40px;
    width: 40px;
    color: #fff;
    border-radius: 50%;
    font-size:25px;
    line-height: 40px;
    font-weight: 700;
}
.DamageCounter.headerDamageCounter{
  right: 14px;
  top: -14px;
  height: 25px;
  width: 25px;
  font-size: 14px;
  line-height: 26px;
}
.CustomerdashboardButton .MuiIconButton-root { font-size: 20px; margin-top: 10px; }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width:768px) and (max-width:1024px) {
  .MuiContainer-fixed { max-width: 100% !important; }
  .AddBtn { top: 20px; left: 2px; }
  .input-group { min-width: 136px; }
  .AccordionPanel .ArrowIcon { right: 1rem; }
  .dashboard-btn span.MuiButton-label { width: calc(100% - 5rem); }
  span.MuiTypography-root.MuiCardHeader-title.MuiTypography-h5.MuiTypography-displayBlock { width: calc(100% - 2rem); font-size: 1rem; }
  .react-tabs__tab-list li { font-size: 13px; }
  .RowData .Mid-Con, .RowData .Last-Con, .ListItem .MuiListItemText-root .MuiTypography-root, .TokenUpRow.RowData .MuiGrid-root, .RowDataHead, .RowData-list-service .MuiGrid-root, .BeltCenveyorStyle, .InfoTabContent, .label-text, .NotificationsHead .MuiInputBase-input, .NotificationsHead .MuiFormLabel-root, .CustomControl .MuiSelect-select.MuiSelect-select { font-size: 14px !important; }
  .MainContInnerStyleTwo { height: calc(100vh - 423px); }
  .Message-div { left: 10px; right: 10px; }
  .input-group { min-width: 73px !important; }
  span.MuiTypography-root.MuiCardHeader-title.MuiTypography-h5.MuiTypography-displayBlock { padding: 0.4rem; }
  .MessageOuter .MuiButton-outlined { font-size: 14px; height: 37px; min-height: 37px; }
  .Message-div { top: 50px; }
  .CustomerdashboardButton { padding-left: 1rem; padding-right: 1rem; position: absolute; left: 0; right: 0; padding-top: 20px; }
  .CustomerdashboardButton .MuiIconButton-root { font-size: 1rem; }
  .ButtonPanelSetting.ButtonPanel .MuiButton-outlined { font-size: 11px; padding-left: 5px; padding-right: 5px; }
  .ButtonPanelSettingWrap {
    margin-left: 0;
    padding-right: 0 !important;
}
.ButtonPanel.ButtonPanelSetting{margin-left: 0;
  margin-right: 0;}
  .Inner_scrollSetting strong, .DatePicker .MuiInput-root input {
    font-size: 13px;
}
.DatePicker .MuiIconButton-label .MuiSvgIcon-root{width: 16px;}

  .ButtonPanelSetting.ButtonPanel .MuiButton-outlined img{ height: 20px; width: 20px !important; object-fit: contain;}
  .Inner_scrollSetting{ padding-right: 10px !important;}
  .ButtonPanel.ButtonPanelSetting .MuiBox-root { margin-top: 10px !important; }
  .container-Checkbox { font-size: 12px !important; line-height: 15px; padding-left: 25px; margin-bottom: 8px; }
  .BeltDamageBottm .flex-fill { flex: inherit; }
  .BeltDamageBottm .ml-2 { margin-left: 3px; }
  .InfoTabContent.one td { padding-bottom: 5px; }
  .MuiOutlinedInput-input {padding: 9.5px 14px 12px!important;font-size: 16px !important;}
}
@media (max-width:1280px) {
  .input-group { min-width: 115px; }
  .BackBtn { margin-left: 0px; }
  .HomeBtn { margin-left: 10px; }
  .MuiFormControl-root .MuiInputLabel-outlined { transform: translate(14px, 13px) scale(1); font-size: 14px; }
  .MuiMenu-list li, .MuiSelect-select { font-size: 14px !important; }
  /* .TimeAndDate { width: 120px; } */
  .BeltCenveyorStyleNoti{ padding-left: 0px !important; padding-right: 0px !important;}

}
@media (min-width:1366px) {
  .CustomerdashboardButton { padding-left: 4rem; padding-right: 4rem; position: absolute; left: 0; right: 0; }
}
@media (min-width:1920px) {
   
  .footer .MuiBox-root.MuiBox-root-15.inner-footer, .footer .MuiBox-root {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
 }
 .footer {
  height: 124px; 
}
  .app-multiselect>div{min-height: 57px;     position: relative;top: -2px;}
  .CustomerdashboardButton .MuiIconButton-root { font-size: 35px; margin-top: 20px; }
  .DatePicker .MuiIconButton-label .MuiSvgIcon-root {right: -10px;}
  .DamageCounter.headerDamageCounter{
    right: 18px;
    top: -18px;
    height: 35px;
    width: 35px;
    font-size: 18px;
    line-height: 35px;
  }
  .DamageCounter {
    background-color: rgb(204, 62, 62);
    position: absolute;
    right: -30px;
    top: -11px;
    height: 35px;
    width: 35px;
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    line-height: 37px;
    font-weight: 700;
}


  
  .DamageButton .MuiButton-outlined { padding: 1px 20px; }
  /* .TimeAndDate img { width: 46px; } */
  .DamageOuter>div { margin-left: -15px; margin-right: -15px; }
  ::-webkit-scrollbar { width: 14px; }
  ::-webkit-scrollbar-track { border: solid 1px #707070; border-radius: 4px; }
  ::-webkit-scrollbar-thumb { background-color: #aaaaaa; border: solid 1px #707070; border-radius: 4px; }
  .Inner_scroll-con { margin-right: -10px; padding-right: 1.6rem !important; }
  /* .TimeAndDate { font-size: 20px; } */
  .BtnLogout { margin-top: 19px; }
  .listCustomerControl { padding-bottom: 0; }
  .label-text-notifi-form { margin-top: 4px; }
  .Message-div { top: 70px; }
  .checkmark { position: absolute; top: 0; left: 0; height: 30px; width: 30px; background-color: #fafafa; border: solid 1px #707070; }
  .Message-div { top: 80px; }
  .Custometable th, .Custometable td, .BasicTable { padding-top: 0.7rem; padding-bottom: 0.7rem; }
  .ConMessageBeltScraper { padding-bottom: 65px; }
  .container-Checkbox .checkmark:after { left: 5px; top: -3px; font-size: 25px; }
  .Tablelast { max-width: 818px !important; }
  button.MuiButtonBase-root .MuiTouchRipple-root{ display: none;}
  /* .RowHeight70 { height: 73px; } */
  .Message-div { top: 105px; padding-top: 0; }
  .react-tabs__tab-panel { padding-top: 75px; }
  .container-Checkbox { font-size: 18px; }
  .TabsView { top: 100px; }
  .react-tabs__tab-list li { font-size: 20px; font-weight: 700; padding: 14px 12px; }
  /* button.buttonDamage { font-size: 35px; } */
  .app-header.mega-header .MuiAppBar-positionFixed { 
      /* height: 237px;    */
    height: 154px;
  }
  .dashboard-grid { 
    /* padding-top: 90px; */
    padding-top:  0px;
   }
  .AdminList.dashboard-grid { padding-top: 110px;}
  .MainContInnerStyleTwo { height: calc(100vh - 655px); }
  .MainContInnerStyleThree { height: calc(100vh - 566px); }
  table .MuiTableCell-head, .MuiTableCell-root { font-size: 18px !important; }
  .tableScrollbar tbody td:first-child, span.Text-righthead, span.Text-Bootmhead { font-size: 20px !important; }
  .cancel-icon img { height: 26px; cursor: pointer; }
  .Pagination .MuiPaginationItem-page { font-size: 20px; }
  .Pagination .MuiPaginationItem-icon { font-size: 40px; }
  .TokenUpRow.RowData .MuiGrid-root, .RowDataHead { font-size: 20px; }
  .CardInnerHeader-title { font-size: 25px !important; }
  .ButtonPanel .MuiButton-outlined img { height: 37px; width: 37px; object-fit: contain; margin-right: 7px; }
  .ButtonPanel .MuiButton-outlined { font-size: 20px; }
  .ButtonPanelSetting.ButtonPanel .MuiButton-outlined { max-width: 370px; width: 100%; padding: 6px 15px; }
  .MessageOuter .MuiButton-outlined { border: 3px solid #707070; font-size: 20px; height: 86px; }
  span.MuiTypography-root.MuiCardHeader-title.MuiTypography-h5.MuiTypography-displayBlock { font-size: 20px; padding: 0.625rem 0.825rem 1rem; height: 73px; }

  /* .BtnLogout{width:120px!important;} */
  .icon-size img { height: auto; cursor: pointer; }
  /* .dashboard-btn span.MuiButton-label { height: 88px; font-size: 35px; } */
  .footer { font-size: 16px; }
  .mega-header .big-logo {
         /* height: 206px; */
     margin-bottom: 7px;margin-top: 14px;}
  .big-logo { height: 90px; }
  .RowData .Mid-Con, .RowData-list-service .MuiGrid-root { font-size: 20px; }
  .RowData .Last-Con, .ListItem .MuiListItemText-root .MuiTypography-root { 
    /* font-size: 30px; */
    font-size: 20px;

   }
  .RowData-btn img { width: 45px; height: 45px; object-fit: contain; }
  .RowData-btn img.AddbtnImg { width: 58px; height: 58px; object-fit: contain; }
  /* .Inner_content { padding-top: 60px; } */
  .input-group { min-width: 153px; }
  /* .TimeAndDate { padding: 15px 5px; min-width: 153px; } */
  .MuiAppBar-positionFixed { height: 207px; }
  .Inner_scroll.Message { padding-bottom: 200px; }
  .RowData .MuiGrid-root.MuiGrid-item.MessageEventTitle { padding-top: 10px !important; }
  .RowData .MuiGrid-root.MuiGrid-item.MessageEventTitleBottom { padding-bottom: 10px !important; }
  .RowData.RowData-list-service { line-height: 30px; }
  .Inner_scroll.SettingsCust { padding-bottom: 235px; }
  .list-user { padding-top: 10px; }
  body { font-size: 1rem !important; }
  .SettingsCustOuter input, .DatePicker .MuiFormControl-root {padding: 3px 10px;
    max-width: 380px;
    width: 100%;}
  .DatePicker .MuiInput-root input { max-width: 320px; width: 100%; }
  .editiconspace { margin-left: 9px; }
  .Inner_scrollTwoTable { padding-bottom: 140px; }
  .CardBorderStyle { padding-left: 25px; padding-right: 25px; }
  .ButtonPanelWrap { padding: 8px 30px !important; }
  .react-tabs__tab-panel, .MuiCardActions-spacing >:not(:first-child) { padding-right: 30px; padding-left: 30px; }
  .ButtonPanel.ButtonPanelSetting .MuiButton-outlined.SubmitSettingBtn{ max-width: 300px !important; min-width: 235px !important; width: auto !important;}

  .PrivateNotchedOutline-legendLabelled-23,.notifications-form fieldset legend{ 
    /* font-size: 1em !important;  */
    font-size: 0.9em !important; 
}
}
@media(min-width:2018px){
  .PrivateNotchedOutline-legendLabelled-28 {
    font-size: 1.1rem;
}
 
}


.AdminDashboard-row .MuiButton-root:hover span.MuiButton-label {
  background: #f5f5f5 !important;
}

/* Search */

.SideBarSearch.input-group button { border: none; background: #008244; }
.SideBarSearch.input-group>.form-control { position: relative; -ms-flex: 1 1 auto; flex: 1 1 auto; width: 1%; margin-bottom: 0; }
.SideBarSearch .form-control { display: block; width: 100%; padding-left: .5em; font-size: 14px; color: #000000; border: none; transition: box-shadow .15s ease-in-out; outline: none; margin: 0; border-radius: 0 !important; }
.SideBarSearch.input-group button img { height: 20px; }
.SideBarSearch .input-group-append {position: relative;top: 2px;display: flex;align-items: center;}
/* .SideBarSearch.input-group button img { height: 17px; } */
/* .SideBarSearch .form-control { height: calc(2.60rem + 2px); } */
/* .SideBarSearch.input-group button img { height: 33px; } */
/* .SideBarSearch { margin-left: 0px; width: 100%; border: 1px solid red; height: 32px;} */
.SideBarSearch { margin-left: 0px; width: 100%; border: 1px solid #707070; height: 36px;}

/* Use border instead of outline for safari */
/* _::-webkit-full-page-media, _:future, :root .SideBarSearch {
  margin-left: 0px; width: 100%; border: 1px solid #707070; height: 32px;
} */

.SideBarSearchContainer {
  height: 43px;
  /* margin-bottom: 3px; */
}

/* Increase search input height because on larger screens it overlaps with the content below */
@media(min-width: 1920px){
  .SideBarSearchContainer {
    height: 70px;
  }

  .SideBarSearch.input-group button img { height: 30px; }
  .SideBarSearch { height: 46px;}

  .Inner_content.with-search-toolbar>.MuiGrid-container { height: calc(100% - 70px) }
}

/* Notifications */

.scrollNotifications { overflow-y: scroll; padding-left: 15px; }
.scrollNotifications .notificationColumn2 {
  padding-left: 5px;
}
.scrollNotifications .notificationColumn3 {
  padding-left: 12px;
}

/* Increase search input height because on larger screens it overlaps with the content below */
@media(max-height: 850px) {
  /* .scrollNotifications { max-height: calc(100vh - 620px); } */
}
@media(min-height: 851px) {  
  .container-Checkbox { display: block; position: relative; padding-left: 35px; cursor: pointer; font-size: 16px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }
  .container-Checkbox input { position: absolute; opacity: 0; cursor: pointer; height: 0; width: 0; }
  .container-Checkbox:hover input ~ .checkmark { background-color: #ccc; }
  .container-Checkbox input:checked ~ .checkmark { background-color: #fafafa; }
  .checkmark:after { content: "X"; position: absolute; display: none; }
  .container-Checkbox input:checked ~ .checkmark:after { display: block; }
  .checkmark { position: absolute; top: 0; left: 0; height: 19px; width: 19px; background-color: #fafafa; border: solid 1px #707070; }
  .container-Checkbox .checkmark:after { left: 3px; top: -2px; width: 5px; height: 5px; border: solid white; border-width: 0; transform: rotate(359deg); font-size: 15px; }
}