.read-more-button {
    color: #535353;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
}

.RowData .Last-Con, .ListItem .MuiListItemText-root .MuiTypography-root {
    text-align: center !important;
}

.ListItemDashboard .MuiListItem-root div{
    text-align: left !important;
}

.selected-scraper{
    background:  #e8f4e6 !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.Mui-disabled.Mui-disabled {
    background: #EBEBEB;
    cursor: not-allowed;
}

.flex-direction-column {
    flex-direction: column;
}

.app-multiselect .css-1rhbuit-multiValue {
    max-width: 255px  !important;
}

.app-multiselect #react-select-3-listbox{
    z-index: 99999999 !important;   
}

.hide-repel .MuiTouchRipple-root{
    display: none !important;
}


.hide-repel.MuiIconButton-root:hover {
    background-color: transparent !important;
}


.settings-time-select label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated,
.settings-date-select label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
    top: -19px;
    font-size: 13px;
    left: 12px;
}

.settings-time-select p.MuiFormHelperText-root.Mui-error,
.settings-date-select p.MuiFormHelperText-root.Mui-error {
    margin-left: 12px;
}

.file-loader-settings {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 999999999;
}

.file-loader-settings .MuiGrid-root.MuiGrid-container {
    background: #d3d3d3;
    /* color: #fff; */
    padding: 10px;
}

.file-loader-settings .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4 {
    justify-content: center;
    align-items: center;
    display: flex;
}

.file-loader-settings li.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding {
    margin-top: 10px;
}

.app-multiselect>div {
    min-height: 40px !important;
}

ul.footer-address {
    display: block;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

ul.footer-address li {
    display: inline-block;
    padding: 0;
    margin-right: 4px;
}

.inline-readmore .display-text-group {
 display: inline-block !important;
}

.refresh-disabled {
    background: #ebebeb;
    cursor: not-allowed;
}

.refresh-disabled a {
    cursor: not-allowed !important;
}

.refresh-disabled a img {
    cursor: not-allowed !important;
}


  

 
 
.record-list .MuiGrid-root.flex-fill.ActionARea table tr td:last-child select {
    width: 288px !important;
}
.AddBtn.icon-size button:hover{
    background: none !important;
}  
.table-list-convey .table {
    max-height: 230px !important;
    overflow: auto;
}

th.TextOverflowTh.unassigner_control {
    border-top: red 2px solid !important;
    border-right: red 2px solid !important;
    border-left: red 2px solid !important;
}
table td.unassigner_control_col {
    border-right: red 2px solid !important;
    border-left: red 2px solid !important;
}

table > tbody > tr > td.MuiTableCell-root.MuiTableCell-body.unassigner_control_last_col {
    border-right: red 2px solid !important;
    border-left: red 2px solid !important;
    border-bottom: red 2px solid !important;
} 

.header-notifications-alert img{
    border-radius:0px !important
}

/* Dropdowns in bottom right row of belt damage detection screen */

.form-control-select {
    min-width: 60px;
    max-width: 60px;
    padding: 1px; 
    border-radius: 0px; 
    border: solid 1px #707070; 
    text-indent: 1px; 
    text-overflow: '';
    padding-left: 15px; 
    overflow: hidden;
    font-size: 16px;
    padding-right: 10px; 
    border-radius: 0px; 
    background-color: white;
}
.form-control-select::-ms-expand { display: none; }

/* padding-right does not work in FF */
/* so we move the text to the left until we only see the first char of the text */
@-moz-document url-prefix() {
    .form-control-select {
        padding-left: 20px;
    }
}

/* make it smaller for safari, so we only see the first char */
_::-webkit-full-page-media, _:future, :root .form-control-select {
    min-width: 50px;
    max-width: 50px;
}
    
/* Center bottom row of belt damage detection screen */

.TableDamgeBelt input {
    height: 22px; 
    font-size: 16px; 
    width: 47px; 
    size: 0; 
    min-width: 63px; 
}

.TableDamgeBelt td { 
    vertical-align: middle; 
}

/* Z-Index for Add Buttons in customer/representation screens */

.AddBtnZ {
    z-index: 99;
}

/* mark email of user in red if invitation email is pending */
.invitation-email-pending {
    color: red;
}